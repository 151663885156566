.App {
  text-align: center;
  display: block;
  padding-top: 100px;
}
.header {
  /* background-color: #fafafa; */
  top: 0;
  width: 100vw;
  height: 55px;
  position: absolute;
}

.github-clone {
  width: 50px;
  position: absolute;
  top: 0;
  right: 0;
  fill: #fafafa;
  padding: 5px 5px 0px 0px;
}

.socialMedia:hover {
  color: #000;
  /* background-color: #fafafa; */
  padding-right: 10px;
  /* width: 60px !important;
  height: 60px !important; */
}
.socialMedia {
  color: #fafafa;
  padding: 20px 10px 0px 0px;
  /* width: 40px !important;
  height: 40px !important; */
  font-size: 40px;
}
.links {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-basis: auto;
  height: auto;
}

.btn {
  text-decoration: none;
  color: #fafafa;
  margin: 5px 10px 10px;
  border-style: solid;
  font-size: larger;
  border-color: #fafafa;
  border-width: 2px;
  padding: 10px;
}

.btn:hover {
  color: #000;
  background-color: #fafafa;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.5rem;
}

@media screen and (min-width: 1024px) {
  .title {
    font-size: 90px;
  }
  .socialMedia {
    font-size: 60px;
  }
  .links {
    display: inline;
  }
}
